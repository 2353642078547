<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                        <h3 style='font-weight: normal' class='mb-1'>Cari Ekle</h3>
                        <div class='text-700'>{{ activeProjectName }}</div>
                    </div>
                </div>


                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3 style='font-weight: normal'>Cari Ekle</h3>

					<p style='font-weight:normal;'>

					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='col-12'>


                    <div class='field grid mb-5'>
                        <label for='officialName' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Resmi Ünvanı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialName' v-model='dataItem.officialName' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='officialAddress' class='col-12 mb-2 md:col-2 md:mb-0'>Fatura Adresi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialAddress' v-model='dataItem.officialAddress' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='officialPostalCode' class='col-12 mb-2 md:col-2 md:mb-0'>Posta Kodu</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='officialPostalCode' v-model='dataItem.officialPostalCode' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='city' class='col-12 mb-2 md:col-2 md:mb-0'>İl<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='city' v-model='dataItem.officialCityId' :options='cityList' optionLabel='name'
                                      option-value='id' placeholder='Seçim Yapın' @change='cityChanged'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.officialCityId > 0'>
                        <label for='town' class='col-12 mb-2 md:col-2 md:mb-0'>İlçe<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='town' v-model='dataItem.officialTownId' :options='townList' optionLabel='name'
                                      option-value='id' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='taxOffice' class='col-12 mb-2 md:col-2 md:mb-0'>Vergi Dairesi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='taxOffice' v-model='dataItem.taxOffice' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='taxNumber' class='col-12 mb-2 md:col-2 md:mb-0'>Vergi Numarası<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='taxNumber' v-model='dataItem.taxNumber' type='text' maxlength />
                        </div>
                    </div>


                    <div class='field grid mb-5'>
                        <label for='phone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Telefon</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='phone' type='text' v-model='dataItem.phone' maxlength='10' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>Firma E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='email' type='text' v-model='dataItem.email' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili Adı, Soyadı</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' v-model='dataItem.personName' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personPhone' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili GSM</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personPhone' type='text' maxlength='10' v-model='dataItem.personPhone' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personEmail' class='col-12 mb-2 md:col-2 md:mb-0'>Yetkili E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personEmail' type='text' v-model='dataItem.personEmail' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='isActive' class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='dataItem.isActive' />
                        </div>
                    </div>


                    <div class='field grid'>
                        <label for='saveButton' class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2'
                                    @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import FirmService from '../../services/firmService';
import store from '@/store';

export default {
    components: { AdminToast },
    _firmService: null,
    created() {
        this._firmService = new FirmService();
        this.activeProjectName = store.getters.getActiveProject.name;
    },
    data() {
        return {
            activeProjectName: '',
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
            },
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {

                this.dataItem.projectId = store.getters.getActiveProject.id;
                let checkResponse = await this._firmService.checkFirmExists(this.dataItem.projectId, this.dataItem.personPhone, this.dataItem.personEmail);
                if (checkResponse.isSuccess) {
                    if (checkResponse.data.length > 0) {
                        showValidationMessage(this, 'Bu telefon numarası ve e-posta ile kayıt var.');
                        return;
                    }
                } else {
                    showValidationMessage(this, 'Firma kontrolünde hata oluştu');
                    return;
                }
                const insertResponse = await this._firmService.insertFirm(this.dataItem);
                if (insertResponse.isSuccess) {
                    showSuccessMessage(this, 'Ekleme işlemi başarılı');
                    setTimeout(async () => await this.$router.push({
                        name: 'editFirm',
                        params: { firmId: insertResponse.data },
                    }), 2000);

                } else {
                    showErrorMessage(this, 'Firma eklemede sorun ile karşılaşıldı');
                }
            }
        },
        validateForm() {
            if (this.dataItem.name == '') {
                showValidationMessage(this, 'Firma Adı alanı boş bırakılamaz');
                return false;
            }

            if (this.dataItem.taxOffice == '') {
                showValidationMessage(this, 'Vergi Dairesi alanı boş bırakılamaz');
                return false;
            }

            if (this.dataItem.taxNumber == '') {
                showValidationMessage(this, 'Vergi Numarası alanı boş bırakılamaz');
                return false;
            }

            return true;
        },
    },
};

</script>


<style scoped>

</style>
